<template>
    <main class="home">
   
      <!-- HEADER TOP MENU BAR -->
        <Header></Header>
     
      <!-- Rest of the HOME PAGE STARTS -->
        <div class="LandingPage">
          <!-- SECTION 1 -->  
          <section  v-for="(data,index) in homePageContentData" :key="index" :class="data.templateType.slug+'BackImage'" > 
            <b-container>
              <Sections :sectionData="data" :sectionType="data.templateType.slug" />
            </b-container>
          </section>
          </div>
          
      <!-- footer section -->
            <section class="footer">
              <footer>
                <cookie-law theme="dark-lime"></cookie-law>
              </footer>
              <Footer/>
            </section>
    </main>
</template>

<script>
import Banner from "../components/Banner";
import Header from "../components/Header";
import TitleText from "../components/TitleText";
import Questionnaire from "../components/Project/Questionnaire";
import Footer from "../components/Footer";

import TimelineTemplate from "../components/TimelineTemplate";
import Flamelink from "../components/FlamelinkTest";
import FAQ from "../components/Accordion";
import Illustration from "../assets/illustration.png";

import Sections from "../components/Section";
import CookieLaw from "vue-cookie-law";

import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    Sections,
    Flamelink,
    TimelineTemplate,
    FAQ,
    Illustration,
    Banner,
    Header,
    TitleText,
    Questionnaire,
    Footer,
 
    CookieLaw,
  },
  computed: {
    ...mapGetters(["language","menu", "content","pageContent"]),
    homePageContentData() {
        var route=this.$route.path.split('/');
        var newArr=[]; 
        if (this.pageContent) {
            newArr= this.pageContent.filter((el)=> {
              if(el.linkedPage.length>0 && el._fl_meta_.status!='review'){
                  return el.linkedPage[0].pageSlug == route[1] ;
              }
                
            });
            
        return newArr;//_.orderBy(newArr, 'orderNumber', 'asc');
        } else return {};
    },
     

   
   
    footerData() {
      if (this.content && this.content.footer) {
        return this.content.footer;
      } else return {};
    },
    currentLanguage() {
      if (this.language) {
        return this.language;
      } else return "english";
    },
  },
  mounted() {
    //alert("Cookie Message here");
  },
};
</script>
