
<template>
    <section class="site-accordion mb4">
        <div class="container">
            <div class="row section-bg">
                <div class="col-md-12 col-sm-12 col-lg-12">
                   <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Collapsible Group Item #1
                                </button>
                            </h2>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                                Some placeholder content for the first accordion panel. This panel is shown by default, thanks to the <code>.show</code> class.
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Collapsible Group Item #2
                                </button>
                            </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div class="card-body">
                                Some placeholder content for the second accordion panel. This panel is hidden by default.
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Collapsible Group Item #3
                                </button>
                            </h2>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                            </div>
                            </div>
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </section>

     <!-- <div class="container">
        <h1>Introduction</h1>
        <hr class="header-underline" />
        <div class="page-accordions">
          <div
            :id="`accordion_${index}`"
            class="accordion"
            v-for="(accordion, index) in accordions"
            :key="`home_accordion_${index}`"
            @click="() => accordionToggle(index)"
          >
            <div class="row">
              <div class="col s10">
                <h3>{{ accordion.question }}</h3>
              </div>
              <div class="col s2">
                <div class="accordion-icon">
                  <i class="fas fa-chevron-down"></i>
                </div>
              </div>
            </div>

            <div>
              <p
                class="accordion-text"
                v-for="(answer, j) in accordion.answer"
                :key="`accordion_${index}_answer_${j}`"
              >
                {{ answer }}
              </p>
            </div>
          </div>
        </div>
      </div> -->
</template>

<script>
export default {
    name: "site-accordion",
    props: {
        data: Array,
        headerField: String,
        contentField: String,
    },
    mounted() {
        this.$nextTick(() => {
            // let elems = document.querySelectorAll('.collapsible');
            // let instances = M.Collapsible.init(elems, {});
        });
    },
    methods: {
        getHeaderField(item) {
            return item[this.headerField];
        },
        getContentField(item) {
            return item[this.contentField];
        }
    }
};
</script>
<style lang="scss">

.site-accordion{
  .section-bg{
       @media screen and (max-width: 600px) {
         padding:2rem 0;
       }
      background-color: #FFFFFF;
           box-shadow: 0 0 24px 7px #1d325c1a;
      padding: 2rem 4rem;
      margin-bottom: 4rem;
  }
  .site-collapsible {
    li{
      border-bottom:1px solid #dcdcdc;
    }
    .collapsible-header {
      padding:0;
      box-shadow: none;
        line-height: 0;
        //border:1px solid black;
        
      &::before{
        background:transparent;
        
      }
      h5{
            margin-top: 7px;
          border-radius: 50%;
          padding-top: 6px;
          font-size:16px;
          color:#472884;
          font-weight: 600;;
      }
      .row{
        margin-bottom:0
      }
    }
    .collapsible-body{
      span {
          
            margin: 0px 20px 20px 0px;
            padding: 5px 0px 5px 10px;
            border: 0;
            background: transparent;
            font-size: 14px;
            line-height: 1.4446;
            font-weight: 100;
            display: block;
            border-left: 3px solid #472884;
      }
      p{
        
            margin: 0;
      }
    }
  }
}
 .accordion-icon {
        //   height: 40px;
        //   width: 40px;
        //   border: 4px #008486 solid;
          float: right;
          margin-top: 0;
          border-radius: 50%;
          padding-top: 0;
          padding-left:0;
          

          i{
            font-size: 18px;
            color: #472884;
            transform: rotate(0deg);
            transition: transform 0.5s ease;
          }
        }
        .site-accordion .site-collapsible {
            .active{
            

            .accordion-icon{
               // background: #008486;
                i{
                color: #472884;
                transform: rotate(-180deg);
                transition: transform 0.5s ease;
                font-size: 18px;
                }
            }
            }
        }
</style>