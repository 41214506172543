<template>
  <div>
    <h2>Flamelink Test</h2>
    <h3>schema id: {{theSchemaKey}}</h3>
    <pre class="screen">{{theDataString}}</pre>
  </div>
</template>

<script>
import {FlamelinkPlugin} from "../services/FlamelinkSettings";
// q.v.: https://flamelink.github.io/flamelink-js-sdk/#/getting-started

// firebase API v8 syntax:
// import firebase from 'firebase/app';
// import 'firebase/firestore';

// import flamelink from 'flamelink/app'
// import 'flamelink/content'

// const firebaseConfig = {
//   databaseURL: 'https://newgalecms.firebaseio.com',
//   apiKey: "AIzaSyBIQ6IV9PkedXsib19yTpG85OMHq37DR08",
//   authDomain: "newgalecms.firebaseapp.com",
//   projectId: "newgalecms",
//   storageBucket: "newgalecms.appspot.com",
//   messagingSenderId: "62201269188",
//   appId: "1:62201269188:web:dc81d1840c440dbcd57291",
//   measurementId: "G-MG92E380H6"
// };
// console.log("firebaseConfig", firebaseConfig);

// // v8 syntax
// const firebaseApp = firebase.initializeApp(firebaseConfig);

// const app = flamelink({
//   firebaseApp,
//   env: 'production',
//   locale: 'en-US',
//   dbType: 'cf' // can be 'rtdb' or 'cf' (Realtime DB vs Cloud Firestore)
// })
// console.log("app", app);

export default {
  name: 'FlamelinkTest', 
  data: function () {
    return {
      theDataString: "",
      theSchemaKey: ""
    }
  },
  async mounted() {
    //console.log("FlamelinkTest::mounted()"); 
    let shckey = "newSchema";
    this.theSchemaKey = shckey;
    try {
      const dataPayload = await FlamelinkPlugin.content.get({ schemaKey: shckey })
      // console.log('All of your ' + shckey + ' : ', dataPayload)
      if(dataPayload == null){
        this.theDataString = "null";
      }else if(dataPayload == undefined){
        this.theDataString = "undefined";
      }else{
        this.theDataString = JSON.stringify(dataPayload, null, ' ');
      }
    } catch (error) {
      console.log("ERROR", error);
    }
    
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

pre.screen {
  background-color: rgb(1, 20, 20);
  color: greenyellow;
  margin: 2rem;
  text-align: left;
  padding: 1rem;
  border-radius: 1rem;
}

pre.screen::before{
  content: "   ";
  position: absolute;
  left: 3rem;
  height: 10rem;
  width: 10rem;
  background-color: rgb(1, 20, 20);
  opacity: 0.5;
  border-color: whitesmoke;
  background: radial-gradient(circle at top left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%);
  border-radius: 1rem 0rem 0rem 0rem;
}
</style>

